<template>
  <div class="clients-modify">
    <v-container class="clients-modify__container" fluid>
      <entity-modify-header class="mb-10" :title="title" />

      <form-builder
        v-if="!isLoading"
        :initial-data="initialData"
        is-tabs
        :tabs="tabs"
        :active-tab="tab"
        @change-tab="changeTab"
        @submit="submit"
      >
        <template #footer="{ valid }">
          <v-row class="footer-row mt-4">
            <v-col>
              <v-btn v-if="tab >= 1" color="secondary" block class="primary--text" @click="changeTab(tab - 1)">
                {{ $t('button.back') }}
              </v-btn>
              <v-btn v-else color="secondary" block class="primary--text" :to="backRoute">{{
                $t('button.cancel')
              }}</v-btn>
            </v-col>
            <v-col>
              <v-btn v-if="tab < tabs.length - 1" type="button" color="primary" block @click.prevent="changeTab">
                {{ $t('button.continue') }}
              </v-btn>
              <v-btn v-else :disabled="!valid" type="submit" color="primary" block>
                {{ submitButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </v-container>
  </div>
</template>

<script>
// Services
import usersService from '@/services/users';
import mediaService from '@/services/media';
import analyticsService from '@/services/analytics';

import { getBaseUrlWithoutApi } from '@/http/getBaseURL';

// Components
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Models
import { schema, isEditSchema, schemaPersonalInfo, schemaAddress } from '@/schemas/client.schema';
import { createModelData } from '@/schemas/createModelData';
import notificationService from '@/services/notification';

// Constants
import { CLIENTS, CLIENTS_DETAILED } from '@/constants/routes';
import { TAB_PERSONAL_INFO, TAB_ADDRESS } from '@/constants/tabs';
import { CREATE_CLIENT, EDIT_CLIENT } from '@/constants/analyticsActions';

export default {
  name: 'ClientsModify',

  components: { EntityModifyHeader, FormBuilder },

  props: {
    isEdit: { type: Boolean, default: false },
  },

  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      // initialData: this.isEdit ? createModelData(isEditSchema) : createModelData(schema),
      initialData: createModelData([...schemaPersonalInfo, ...schemaAddress]),
      schemaList: [schemaPersonalInfo, schemaAddress],
      isUpload: false,
      tab: 0,
      tabs: [
        {
          label: 'tab.personal_info',
          tab: TAB_PERSONAL_INFO,
          schema: schemaPersonalInfo,
        },
        {
          label: 'tab.address',
          tab: TAB_ADDRESS,
          schema: schemaAddress,
        },
      ],
    };
  },

  computed: {
    submitButtonText() {
      return this.$t(this.isEdit ? 'button.edit_client' : 'button.add_client');
    },

    backRoute() {
      if (this.isEdit) {
        return {
          name: CLIENTS_DETAILED,
          params: {
            id: this.id,
          },
        };
      }
      return this.$route.params.prevPage || { name: CLIENTS };
    },

    title() {
      return this.isEdit ? this.$t('client.edit_client') : this.$t('client.add_client');
    },

    currentSchema() {
      return this.isEdit ? isEditSchema : schema;
    },

    schema() {
      return this.schemaList[this.tab];
    },
  },

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      usersService
        .getClientById(this.id)
        .then(async client => {
          const newClient = { ...client };

          newClient.usages = newClient.usages.map(usage => {
            const newUsage = { ...usage };

            if (usage.unit.parentUnit) {
              newUsage.unit = {
                id: usage.unit.parentUnit,
                name: usage.unit.parentUnitName,
              };

              newUsage.room = {
                id: usage.unit.id,
                name: usage.unit.name,
              };
            }

            newUsage.salesContractDate = newUsage.salesContractDate
              ? newUsage.salesContractDate
              : `${newUsage.rentalPeriodStartDate} - ${newUsage.rentalPeriodEndDate}`;

            return newUsage;
          });

          newClient.passportScan = newClient.passportScan
            ? await this.formatMedia([{ id: 1, url: newClient.passportScan }])
            : [];
          newClient.idScan = newClient.idScan ? await this.formatMedia([{ id: 1, url: newClient.idScan }]) : [];

          this.initialData = newClient;
        })

        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    submit(data) {
      this.isUpload = true;

      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      const requestBody = this.createBodyRequest(data);

      const saveClient = this.isEdit ? usersService.updateClientById : usersService.addClient;
      analyticsService.track(this.isEdit ? EDIT_CLIENT : CREATE_CLIENT);

      saveClient(requestBody, this.id)
        .then(client => {
          notificationService.success(this.isEdit ? this.$t('client.edited') : this.$t('client.created'), 2000);
          this.$router.push({ name: CLIENTS_DETAILED, params: { id: client.id || this.id } });
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.isUpload = false;
        });
    },
    createBodyRequest(data) {
      const usages = data.usages.map(usage => {
        const newUsage = { ...usage };

        delete newUsage.building;
        delete newUsage.project;

        newUsage.unit = newUsage.room?.id || newUsage.unit.id;

        if (newUsage.clientType !== 'owner') {
          const dates = newUsage.salesContractDate?.split(' - ');
          newUsage.salesContractDate = null;
          [newUsage.rentalPeriodStartDate, newUsage.rentalPeriodEndDate] = dates;
        } else {
          newUsage.rentalPeriodStartDate = null;
          newUsage.rentalPeriodEndDate = null;
        }

        return newUsage;
      });

      let passportScan;
      let idScan;
      let idNumber;

      if (data.passportScan[0]) {
        passportScan = data.passportScan[0].backgroundFileId ? data.passportScan[0].backgroundFileId : undefined;
      } else passportScan = null;

      if (data.idScan[0]) {
        idScan = data.idScan[0].backgroundFileId ? data.idScan[0].backgroundFileId : undefined;
      } else idScan = null;

      if (this.isEdit) {
        idNumber = data.idNumber || '';
      } else {
        idNumber = data.idNumber || undefined;
      }

      return {
        firstName: data.firstName,
        lastName: data.lastName || '',
        passport: data.passport || '',
        idNumber,
        passportScan,
        idScan,
        country: data.country?.value || undefined,
        phone: data.phone || '',
        email: data.email,
        isSendPasswordWhenCreating: data.isSendPasswordWhenCreating,
        usages,
      };
    },
    async formatMedia(media) {
      const newMedia = await Promise.all(
        media.map(async file => {
          const newFile = { ...file };
          newFile.name = decodeURIComponent(newFile.url)
            .split('/')
            .pop();

          const { url, blob } = await this.getPrivateMediaObject(newFile.url);

          newFile.url = url;
          newFile.type = blob.type;
          newFile.size = blob.size;
          return newFile;
        })
      );

      return newMedia;
    },
    getPrivateMediaObject(url) {
      return mediaService.getPrivateMediaObject(getBaseUrlWithoutApi() + url);
    },
    changeTab(newTab) {
      this.tab = typeof newTab === 'number' ? newTab : this.tab + 1;
    },
  },

  notificationItem: null,
};
</script>

<style lang="scss">
.clients-modify {
  &__container {
    max-width: 548px !important;
  }
}
</style>
